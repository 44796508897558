import {Table} from "antd";

function ChargingProtocols() {

    const dataSource = [
        {
            key: '1',
            furnace: '#1',
            weight_charged: 16000,
            weight_poured: 18000,
            max_temp: 1670,
            duration: 105,
            timestamp: "2023-07-04T14:38:00Z",
        },
        {
            key: '2',
            furnace: '#2',
            weight_charged: 15000,
            weight_poured: 17000,
            max_temp: 1680,
            duration: 105,
            timestamp: "2023-07-04T15:48:00Z",
        },
    ];

    const columns = [
        {
            title: 'Furnace',
            dataIndex: 'furnace',
            key: 'furnace',
        },
        {
            title: 'Charged Weight',
            dataIndex: 'weight_charged',
            key: 'weight_charged',
        },
        {
            title: 'Poured Weight',
            dataIndex: 'weight_poured',
            key: 'weight_poured',
        },
        {
            title: 'Max. Temperature',
            dataIndex: 'max_temp',
            key: 'max_temp',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Time',
            dataIndex: 'timestamp',
            key: 'timestamp',
        },
    ];

    return (
        <Table dataSource={dataSource} columns={columns} />
    )
}

export default ChargingProtocols