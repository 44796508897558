import React from "react";
import { useState, useEffect } from 'react';
import { Card, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import {GetUrlPrefix} from "../../config/HostConfig";
const { Meta } = Card;


function AppCard({name, port, logo, available}) {

    //Localisation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    //Set initial state
    const [reachable, setReachable]=useState(available ? 1 : 0)


    async function getStatusFromApp () {
        try {
          let response = await fetch (GetUrlPrefix("") + ':' + port + '/api/status');
          const pingAnswer = await response.text()
          console.log("pingAnswer:" + pingAnswer)
          switch (pingAnswer){
            case "ok":
                setReachable(1);
                break;
            case "idle":
                setReachable(2)
                break;
            default: 
                setReachable(1);
          }
          console.log(reachable);
        } catch (error) {
          console.log(error);
        }
      }
    
    function cardStatus (status) {
        let statusDisplay;
        switch (status){
            case 0:
                statusDisplay="error"
                break;
            case 1:
                statusDisplay="success" 
                break;
            case 2:
                statusDisplay="warning"
                break;
            default:
                statusDisplay="error"

        } return statusDisplay;
    }

    function cardText (status) {
        let textDisplay;
        switch (status){
            case 0:
                textDisplay= t('applications.status.error')
                break;
            case 1:
                textDisplay= t('applications.status.active') 
                break;
            case 2:
                textDisplay=t('applications.status.noData')
                break;
            default:
                textDisplay= t('applications.status.error')

        } return textDisplay;

    }

    const openApp = () => {
        window.open("/applications/" + port, "_self");
    }

    return (
        <div onClick={() => openApp()}>
            <Card
                hoverable
                //extra={<a href="#">More</a>}
                style={{
                    width: 240,
                    padding: 5,
                    margin: 5,
                }}
                cover=
                {available
                    ? <img alt={name} src={"/img/" + logo} />
                    : <img alt={name} style={{ opacity: 0.4}} src={"/img/" + logo} />
                }
            >
                {available
                    ? <Meta title={name}/>
                    : <Meta title={name} description={t('applications.status.notActivated')}/>
                }
                <Badge status = {cardStatus(reachable)} text = {cardText(reachable)} />
                
                
            </Card>
        </div>
    );
}



export default AppCard;