/**
 * Provides the URL that is required to send an API call to a backend service.
 *
 * @param {string} service The identifier of the service. See Service Overview Confluence page the complete list.
 * @returns {string} The URL (http protocol, domain, and API path for the service).
 * @constructor
 */
export function GetUrlPrefix(service) {

    const HostConfigLocation = window.location.origin

    function IdentifyPrefix (service) {

        function IsDomain() {
            return HostConfigLocation.startsWith("https");
        }

        switch (service) {
            // Controller
            case "application-controller": return IsDomain() ? "/api/application-controller" : ":25300"
            case "device-controller": return IsDomain() ? "/api/device-controller" : ":25100"
            case "interface-controller": return IsDomain() ? "/api/interface-controller" : ":25200"
            // Connector
            case "mqtt-connector": return IsDomain() ? "/api/mqtt-connector" : ":25220"
            case "opcua-connector": return IsDomain() ? "/api/opcua-connector" : ":25210"
            case "sql-connector": return IsDomain() ? "/api/sql-connector" : ":25230"
            // Applications
            case "app-cooling-water": return IsDomain() ? "/api/app-cooling-water" : ":25308"
            case "app-condition-monitoring": return IsDomain() ? "/api/app-condition-monitoring" : ":25302"
            case "app-crucible-monitoring": return IsDomain() ? "/api/app-crucible-monitoring" : ":25303"
            case "app-data-aggregator": return IsDomain() ? "/api/app-data-aggregator" : ":25311"
            case "app-furnace-simulation": return IsDomain() ? "/api/app-furnace-simulation" : ":25312"
            case "app-inductor-management": return IsDomain() ? "/api/app-inductor-management" : ":25313"
            case "app-kpi": return IsDomain() ? "/api/app-kpi" : ":25301"
            case "app-opticharge": return IsDomain() ? "/api/app-opticharge" : ":25305"
            case "app-pouring-support": return IsDomain() ? "/api/app-pouring-support" : ":25306"
            case "app-quick-input": return IsDomain() ? "/api/app-quick-input" : ":25309"
            case "app-sialogic-monitor": return IsDomain() ? "/api/app-sialogic-monitor" : ":25310"
            case "app-smart-melt": return IsDomain() ? "/api/app-smart-melt" : ":25304"
            case "app-smart-recipe": return IsDomain() ? "/api/app-smart-melt" : ":25307"
            default:
                return ""
        }
    }

    return HostConfigLocation + IdentifyPrefix(service)
}

/**
 * Provides the information if the domain of the webpage is TLS secured or not.
 *
 * @returns {boolean}
 * @constructor
 */
export function ConnectionIsSecure() {
    return window.location.origin.startsWith("https")
}

/**
 * Provides the websocket protocol
 *
 * @returns {string} [websocket protocol]://
 * @constructor
 */
export function GetWebsocketProtocol() {
    if (ConnectionIsSecure()) {
        return "wss://"
    } else {
        return "ws://"
    }
}
