import Plot from "react-plotly.js";
import {Modal} from "antd";
import {useState} from "react";

function HistoricData() {

    const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);

    const showTimeModal = () => {
        setIsTimeModalOpen(true);
    };
    const handleTimeModalOk = () => {
        setIsTimeModalOpen(false);
    };
    const handleTimeModalCancel = () => {
        setIsTimeModalOpen(false);
    };

    var dateIcon = {
        'width': 500,
        'height': 600,
        'path': 'M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z'
    }

    return (
        <div>
            <Plot
                data={[
                    {
                        x: ['2020-10-04', '2021-11-04', '2023-12-04'],
                        y: [9000, 5111, 6000],
                        type: 'scatter',
                        name: 'Weight - Furnace #1',
                        // yaxis: 'y1',
                    },
                    {
                        x: ['2020-10-04', '2021-11-04', '2023-12-04'],
                        y: [190, 140, 160],
                        type: 'scatter',
                        name: 'Temperature- Furnace #1',
                        // yaxis: 'y2',
                    },
                    {
                        x: ['2020-10-04', '2021-11-04', '2023-12-04'],
                        y: [9500, 5500, 6500],
                        type: 'scatter',
                        name: 'Weight - Furnace #2',
                        // yaxis: 'y1',
                    },
                    {
                        x: ['2020-10-04', '2021-11-04', '2023-12-04'],
                        y: [195, 145, 165],
                        type: 'scatter',
                        name: 'Temperature- Furnace #2',
                        // yaxis: 'y2',
                    },
                ]}
                layout={{
                    // dragmode: 'pan',
                    title: 'A Fancy Plot',
                    // yaxis: {
                    //     title: 'Gewicht [kg]',
                    // },
                    // yaxis2: {
                    //     title: 'Temp [°C]',
                    //     side: 'right',
                    // },
                }}
                config={{
                    displaylogo: false,
                    displayModeBar: true,
                    modeBarButtonsToRemove: ['lasso2d', 'select2d', 'autoScale2d'],
                    modeBarButtonsToAdd: [
                        {
                            name: 'Time selection',
                            icon: dateIcon,
                            click: function () {
                                showTimeModal()
                            }
                        }
                    ],
                }}

            />
            <Modal title="Select Time" open={isTimeModalOpen} onOk={handleTimeModalOk} onCancel={handleTimeModalCancel}>
                <p>Some contents...</p>
            </Modal>
        </div>
    )
}

export default HistoricData