import {Divider} from "antd";
import LiveData from "./LiveData";
import HistoricData from "./HistoricData";
import ChargingProtocols from "./ChargingProtocols";

function KpiApp() {


    return (
        <div>
            <h2>Live Data</h2>

            <h3>Furnace #1</h3>

            <LiveData weight={11893} temp={1120} duration={40} />

            <h3>Furnace #2</h3>

            <LiveData weight={11893} temp={1120} duration={40} />

            <Divider />

            <h2>Historic Data</h2>

            <HistoricData />

            <Divider />

            <h2>Charging Protocols</h2>

            <ChargingProtocols />

        </div>
    )
}

export default KpiApp
