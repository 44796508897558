import React from "react";
import AppCard from "../components/apps/AppCard";

function AppPage() {


    return (
        <div style={{ display: "flex", flexWrap: "wrap"}}>
            <AppCard name="Condition Monitoring" port="condition-monitoring" logo="app-condition-monitoring.png" available={true} />
            <AppCard name="Crucible Index" port="25303" logo="app-crucible-index.png" available={true} />
            <AppCard name="KPI" port="kpi" logo="app-kpi.png" available={true} />
            <AppCard name="OptiCharge" port="opticharge" logo="app-opticharge.png" available={true} />
            <AppCard name="Pouring Support" port="25306" logo="app-pouring-support.png" available={false} />
            <AppCard name="Smart Melt" port="smartmelt" logo="app-smart-melt.png" available={true} />
            <AppCard name="Smart Recipe" port="25307" logo="app-smart-recipe.png" available={false} />
            <AppCard name="Cooling Water Basic" port="watercooling-basic" logo="app-water-cooling.png" available={true} />
            <AppCard name="Cooling Water Pro" port="watercooling-pro" logo="app-water-cooling.png" available={true} />
            {/*<AppCard name="PLATZHALTER" port="PLATZHALTER" logo="PLATZHALTER.png" available={true} />*/}
            <AppCard name="InduTrack" port="indutrack" logo="app-indutrack.png" available={true} />
        </div>
    );
}

export default AppPage;