import './OptiChargeApp.css';
import React, { useState, useEffect } from 'react';
import { Select, Layout, Card, Form } from 'antd';
import Light from "./components/Light";
import PreReqTable1 from './components/PreReqTable1';
import PreReqTable2 from './components/PreReqTable2';

//localisation
import { useTranslation } from 'react-i18next';
import {ConnectionIsSecure, GetUrlPrefix, GetWebsocketProtocol} from "../../../config/HostConfig";

const { Option } = Select;

//Build panel constants
const { Header, Content, Footer } = Layout;

function OptiChargeApp() {

  //----------------------------Localisation----------------------
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  //Set initial State
  const [registeredDevices, setRegisteredDevices]=useState([])
  const [furnaceId, setFurnaceId] = useState (1)
  const [dashboardId, setDashboardId] = useState()
  const [form] = Form.useForm();
  
  const [signalData, setSignalData] = useState([])
  const [instruction, setInstruction] = useState("")

  useEffect(() => {
    let url = GetWebsocketProtocol() + window.location.hostname + ':25305/ws';
    let c = new WebSocket(url);
    c.onopen = function() {
      console.log('WS connected');
    };
    c.onmessage = function(msg){
      let data = JSON.parse(msg.data)
      if (data.length !== 0) {
        setSignalData(data)
      }
      
    } 
    getDevices();
  }, []);

  useEffect(() => {
    // Update labels when data changed
    updateInstructionLabel()

  }, [signalData])

  
  useEffect(() => {
    // Set instructions to inactive if no value provided by back end service
    updateInstructionLabel()

    const deviceProcessArray = [...registeredDevices];
    deviceProcessArray.forEach(( element ) => {
    if (element.id === furnaceId) {
        element.app_attributes.forEach((app_attribute) => {
        if ( app_attribute.identifier === "dashboard_id_opticharge") {
            setDashboardId(app_attribute.value);
        }
        })
    }
    })
  }, [furnaceId])
   

  useEffect(() => {
    if (registeredDevices.length > 0) {
      setFurnaceId(registeredDevices[Object.keys(registeredDevices)[0]].id)
      form.setFieldsValue({Selection: registeredDevices[Object.keys(registeredDevices)[0]].name})
    }

    const deviceProcessArray = [...registeredDevices];
    deviceProcessArray.forEach(( element ) => {
    if (element.id === furnaceId) {
        element.app_attributes.forEach((app_attribute) => {
        if ( app_attribute.identifier === "dashboard_id_opticharge") {
            setDashboardId(app_attribute.value);
        }
        })
    }
    })
  }, [registeredDevices]);

  //Get registered devices
  async function getDevices () {
  try {
    let response = await fetch(GetUrlPrefix("device-controller") + `/applications/opticharge/devices?detailed=true`);
    const jsonData = await response.json();
    setRegisteredDevices(jsonData)
  } catch (error) {
    console.log(error);
  }
}

function updateInstructionLabel() {
  if (signalData[furnaceId] !== undefined) {
    switch(signalData[furnaceId].SignalLight){
      case 1:
        setInstruction(t('applications.opticharge.instruction.charge'))
        break;
      case 2:
        setInstruction(t('applications.opticharge.instruction.hold'))
        break;
      case 3:
        setInstruction(t('applications.opticharge.instruction.stop'))
        break;
      default:
        setInstruction(t('applications.opticharge.instruction.inactive'))
    }
  } else {
    setInstruction(t('applications.opticharge.instruction.inactive'))
  }
}

  const changeFurnace = (value)  => {
    setFurnaceId(value)
  }

  const keyIsKnown = (key) => {
    return signalData[key] !== undefined
  }

  return (
    <div className="app" style={{marginLeft: 9, marginRight: 9}}>
    <Layout >
      <Form form={form} name="FurnaceDropdown">
        <Form.Item name="Selection" style={{marginBottom: "0px"}}>
          <Select style={{ width: 160, marginBottom: 16  }}
              showArrow
              onChange={changeFurnace}
          >
            {
              registeredDevices.map((device) => {
                { return(<Option key={device.id} value={device.id}>{device.name}</Option>)}
              })
            }
          </Select>
        </Form.Item>
      </Form>
    <Content style={{ padding: '25px 50px'}}>
      <Card title= "Status" headStyle={{textAlign: 'center'}} style={{ margin: '0px 0px 25px 0px'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <Light id={1} input={keyIsKnown(furnaceId) ? signalData[furnaceId].SignalLight : 0} />
          <Light id={2} input={keyIsKnown(furnaceId) ? signalData[furnaceId].SignalLight : 0} />
          <Light id={3} input={keyIsKnown(furnaceId) ? signalData[furnaceId].SignalLight : 0} />
        </div>
        <div style={{textAlign: 'center'}}><h1 style={{fontSize: '35px'}}>{instruction}</h1></div>
      </Card>
      <iframe
        style={{minHeight:"400px"}} 
        src= {GetUrlPrefix("device-controller") + `${dashboardId}&kiosk&refresh=10s&from=now-1h&to=now`}
        width="100%" 
        height="100%"
        frameBorder="0"
      ></iframe>
      <div style = {{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 64}}>
        <div style = {{width: "48%"}}><PreReqTable1 indicatorData={signalData} furnaceId={keyIsKnown(furnaceId) ? furnaceId : 0} /></div>
        <div style = {{width: "48%"}}><PreReqTable2 indicatorData={signalData} furnaceId={keyIsKnown(furnaceId) ? furnaceId : 0} /></div>
      </div>
    </Content>
    <Footer>&copy; {new Date().getFullYear()} ABP Induction</Footer>
    </Layout>
   </div>
  );
}

export default OptiChargeApp;