import {Col, Row, Statistic} from "antd";

function LiveData({weight, temp, duration}) {
    return (
        <Row gutter={24}>
            <Col span={8}>
                <Statistic
                    title="Weight"
                    value={weight}
                    suffix="kg"
                />
            </Col>
            <Col span={8}>
                <Statistic
                    title="Temperature"
                    value={temp}
                    suffix="°C"
                />
            </Col>
            <Col span={8}>
                <Statistic
                    title="Charge Duration"
                    value={duration}
                    suffix="hh:mm"
                />
            </Col>
        </Row>
    )
}

export default LiveData